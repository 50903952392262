const config = {
  baseUrl: process.env.NODE_ENV === 'development' ? 'http://localhost:5322/api/' : '/api/',
  healthCheckUrl: '/healthcheck',
  healthCheckInterval: 3000,
  delay: 500,
  getDefaultHeaders() {
    return {
      Authorization: 'bearer ' + localStorage.getItem('token'),
    };
  },
  clients: {},
};

const formDataContentType = 'application/json';
const entityTypes = [
  'rental',
  'rentalRequest',
  'rentalObject',
  'building',
  'debtor',
  'rentalObjectKey',
  'sapSettings',
];

for (const entityType of entityTypes) {
  config.clients[entityType] = {
    create: {
      url: '/create',
      method: 'post',
      headers: {
        'Content-Type': formDataContentType,
      },
    },
    update: {
      url: '/update',
      method: 'put',
      headers: {
        'Content-Type': formDataContentType,
      },
    },
    delete: {
      url: '/delete',
      method: 'delete',
    },
    archive: {
      url: '/archive',
      method: 'put',
    },
    restore: {
      url: '/restore',
      method: 'put',
    },
    query: {
      url: '/query',
      method: 'post',
    },
    count: {
      url: '/count',
      method: 'post',
    },
    excel: {
      url: '/export/excel',
      method: 'post',
      responseType: 'blob',
    },
    pdf: {
      url: '/export/pdf',
      method: 'post',
      responseType: 'blob',
    },
  };
}

config.clients['rentalRequest']['addComments'] = {
  url: '/addComments',
  method: 'post',
  headers: {
    'Content-Type': formDataContentType,
  },
};

config.clients['rentalRequest']['handle'] = {
  url: '/handle',
  method: 'post',
  headers: {
    'Content-Type': formDataContentType,
  },
};

config.clients['rental']['complete'] = {
  url: '/complete',
  method: 'post',
  headers: {
    'Content-Type': formDataContentType,
  },
};

config.clients['rental']['check'] = {
  url: '/check',
  method: 'get',
  headers: {
    'Content-Type': formDataContentType,
  },
};

config.clients['rental']['uncompleted'] = {
  url: '/uncompleted',
  method: 'get',
  headers: {
    'Content-Type': formDataContentType,
  },
};

config.clients['building']['getRoomSvgMap'] = {
  url: '/GetRoomSvgMap',
  method: 'get',
  responseType: 'blob',
};

config.clients['sapSettings']['get'] = {
  url: '/get',
  method: 'get',
};

export default config;
export const reportConfigs = {
  roomUsageReport: {
    url: '/roomUsageReport',
    method: 'post',
    responseType: 'blob',
  },
  buildingUsageReport: {
    url: '/buildingUsageReport',
    method: 'post',
    responseType: 'blob',
  },
  debtorReport: {
    url: '/debtorReport',
    method: 'post',
    responseType: 'blob',
  },
  debtorsReport: {
    url: '/debtorsReport',
    method: 'post',
    responseType: 'blob',
  },
  rentalObjectDisposalPercentage: {
    url: '/rentalObjectDisposalPercentage',
    method: 'post',
    responseType: 'blob',
  },
  rentalObjectTimeDisposalPercentage: {
    url: '/rentalObjectTimeDisposalPercentage',
    method: 'post',
    responseType: 'blob',
  },
};
export const sapConfigs = {
  'isOrdersCreated': {
    url: '/orders/created',
    method: 'get',
  },
  'isOrdersCreating': {
    url: '/orders/creating',
    method: 'get',
  },
  'isOrdersPrepared': {
    url: '/orders/prepared',
    method: 'get',
  },
  'isOrdersPreparing': {
    url: '/orders/preparing',
    method: 'get',
  },
  'getOrders': {
    url: '/orders/query',
    method: 'post',
  },
  'getItems': {
    url: '/orders/items/query',
    method: 'post',
  },
  'getErrors': {
    url: '/orders/errors/query',
    method: 'post',
  },
  'createOrders': {
    url: '/orders/create',
    method: 'post',
  },
  'prepareOrders': {
    url: '/orders/prepare',
    method: 'post',
  },
  'deleteOrders': {
    url: '/orders/delete',
    method: 'delete',
  },
  excel: {
    url: '/orders/export/excel',
    method: 'post',
    responseType: 'blob',
  },
  pdf: {
    url: '/orders/export/pdf',
    method: 'post',
    responseType: 'blob',
  },
};
